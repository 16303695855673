// 首页内容相关模块
import request from '@/utils/request'

// 获取类目
export const searchListCategory = () => {
  return request({
    method: 'GET',
    url: '​/api/search​/listCategory'
  })
}

// 获取表格内容
export const searchList = data => {
  return request({
    method: 'POST',
    url: '​​/api/search​/list',
    data
  })
}

// 查询价格
export const queryCoin = data => {
  return request({
    method: 'POST',
    url: '​/api/order/queryCoin',
    data
  })
}

// 创建订单 
export const createOrder = data => {
  return request({
    method: 'POST',
    url: '​/api​/order​/createOrder',
    data
  })
}

// 搜索关键字
export const searchSamples = () => {
  return request({
    method: 'GET',
    url: '​/api/search/samples'
  })
}

// 搜索获取案例值
export const searchscmple = params => {
  return request({
    method: 'get',
    url: '​/api/search/sample',
    params
  })
}

// 搜索提示
export const queryUserCoin = (data) => {
  return request({
    method: 'POST',
    url: '​/api/user/queryUserCoin',
    data
  })
}

// 是否展示弹框
export const userConfig = data => {
  return request({
    method: 'POST',
    url: '​/api/userconfig/change',
    data
  })
}

// 获取价格下拉数据
export const searchPriceList = () => {
  return request({
    method: 'GET',
    url: '​/api/search​/price'
  })
}

// 获取每日新增链接数量
export const searchDailyIncr = () => {
  return request({
    method: 'GET',
    url: '​/api/search​/dailyIncr'
  })
}

// 获取视频教程列表
export const getTutorialVideos = params => {
  return request({
    method: 'GET',
    url: '​/api/tutorial/videos',
    params
  })
}

// 记录引流快现形的次数
export const records = data => {
  return request({
    method: 'POST',
    url: '/api​/stat/record',
    data
  })
}

// 暗度购物车查询积分
export const queryCarCoin = data => {
  return request({
    method: 'POST',
    url: '​/api/order/queryCarCoin',
    data
  })
}

// 暗度购物车批量添加
export const createCarOrder = data => {
  return request({
    method: 'POST',
    url: '​/api/order/createCarOrder',
    data
  })
}
