<template>
  <div>
    <headers />
    <div class="FAQ-page">
      <div class="container">
        <div class="content" >
          <a href="https://www.kuaixianxing.com" target="_blank">
          <img @click="handleSelect" src="../../assets/home/pckxx1.png" alt="" />
          </a>
        </div>
      </div>
    </div>
    <right-icon />
  </div>
</template>

<script>
import Headers from '@/components/Header/index.vue'
import RightIcon from '@/components/right-icon/index.vue'
import { records } from '@/api/home.js'
export default {
  name: 'appraise',
  components: {
    Headers,
    RightIcon
  },
  props: {},
  data() {
    return {

    }
  },
  computed: {},
  watch: {},
  beforeRouteLeave(to, from, next) {
    if (to.path === '/recharge') {
      this.$route.meta.keepAlive = true
    } else {
      this.$route.meta.keepAlive = false
    }
    next()
  },
  methods: {
    handleSelect(){
      records({ type: 'kxx' })
    }
  }
}
</script>

<style scoped lang="scss">
.FAQ-page {
  display: flex;
  justify-content: center;
  background: #f5f6fa;
  padding: 80px 0 80px;
  .container {
    width: 1200px;
  }
  .txt-title {
    display: flex;
    margin-bottom: 40px;
    .left-box {
      width: 4px;
      height: 26px;
      border-radius: 2px;
      background-color: #1b4eff;
      margin-right: 16px;
    }
    span {
      font-size: 28px;
      color: #373e4f;
      font-weight: 700;
      margin-top: -7px;
    }
  }
  .content {
    width: 1200px;
    img {
      width: 100%;
    }
  }
}
</style>
